<template>
	<div class="SupplierType">
		<p class="Top-Tips">请选择您可提供的产品类型及服务区域，以便您收到相应的询价！</p>
		<p class="Company-Type">我能提供的产品类型</p>
		<div class="Type-Check">
			<span v-for="(item,index) in supplierInfoData" :class="item.checkLabel ? 'Name-Span Name-Span-Check' : 'Name-Span'" @click="checkName(index)" :key="index">
				{{item.name}}
			</span>
		</div>
    <!-- 业务范围 -->
		<Bussiness></Bussiness>
	</div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { mapState,mapActions,mapMutations } from 'vuex'
import Api from '@/components/Api/index'
import Bussiness from '@/components/Register/Bussiness'
export default {
	name:  'SupplierType',
	components: {
		Bussiness
	},
	// 定义属性
	data() {
		return {
			
		}
	},
	// 计算属性，会监听依赖属性值随之变化
	computed: {
		...mapState('supplier',['supplierKey','supplierInfoData']),
		nameWidthLength() {
			return function(val) {
				if (val.length == 2) return '1.5rem';
				if (val.length == 4) return '2.1rem';
				if (val.length == 5) return '2.2rem';
				if (val.length == 6) return '2.3rem';
				else return '2.5rem';
			}
		}
	},
	// 监控data中的数据变化
	watch: {},
	// 方法集合
	methods: {
		...mapActions('supplier',['SET_GET_INFO_DATA']),
		...mapMutations('supplier',['UPDATE_GET_INFO_DATA_MU']),
		//请求key值所返回的数据
		_getData() {
			this.SET_GET_INFO_DATA({
				supplierKey: this.supplierKey,
				success: () => {},
				fail: () => {}
			})
		},
		checkName(index) {
     this.UPDATE_GET_INFO_DATA_MU(index)
		} 
	},
	// 生命周期 - 创建完成（可以访问当前this实例）
	created() {
		
	},
	// 生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		this._getData()
	},
	beforeCreate() {}, // 生命周期 - 创建之前
	beforeMount() {}, // 生命周期 - 挂载之前
	beforeUpdate() {}, // 生命周期 - 更新之前
	updated() {}, // 生命周期 - 更新之后
	beforeDestroy() {}, // 生命周期 - 销毁之前
	destroyed() {}, // 生命周期 - 销毁完成
	activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
	.Top-Tips {
		color: rgba(153, 153, 153, 1);
		font-size: 0.28rem;
		font-family: PingFangSC-light;
	}
	.SupplierType {
		padding: 0.2rem;
		.Company-Type {
			margin-top: 20px;
			color: rgba(0, 123, 203, 1);
			font-size: 0.32rem;
			font-weight: 700;
			font-family: PingFangSC-semiBold;
		}
		.Type-Check {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 20px;
			.Name-Span {
        border-radius: 0.16rem;
				background-color: rgba(238, 238, 238, 1);
				color: rgba(16, 16, 16, 1);
				font-size: 0.28rem;
				text-align: center;
				font-family: -apple-system;
				//padding: 0.2rem;
				line-height: 3;
				width: 2.2rem;
				font-size: 0.26rem;
				margin-bottom: 10px;
			}
			.Name-Span-Check {
				background-color: rgba(229, 78, 23, 1);
				color: rgba(255, 255, 255, 1);
				box-shadow: 0px 0.04rem 0.12rem 0px rgba(0, 0, 0, 0.28);
				font-family: -apple-system;
			}
		}
	}
</style>