<template>
	<div class="Bussiness">
		<div class="Initial-Business" 
		     v-for="(item,index) in supplierInfoData.filter(x => x.checkLabel).map(c => c.businessList).flat()"
				 :key="index">
		  <div class="Initial-Business-Top">
				<span class="Initial-Name Top-Tips">
					{{item.name}}
				</span>
				<div class="Initial-right">
					<span>维护</span>
					<svg-icon style="margin-top: 1px;" @click="resourceSelect(item,index)" iconClass="add1"></svg-icon>
				</div>
			</div>		 

			<div class="Initial-Content-Resource" v-for="(resource,resourceIndex) in item.resourceAgents" :key="resourceIndex">
				<span class="ICR-Name">{{resource.nameCn}}</span>
				<div class="ICR-Right">
					<div class="check-top-swich">
						<span @click="checkClike(1,item.resourceAgents,resource,index)" :class="resource.agentType === 1 ? 'swich-isActive' : ''">资源方</span>
						<span @click="checkClike(0,item.resourceAgents,resource,index)" :class="resource.agentType === 0 ? 'swich-isActive' : ''">代理</span>
					</div>
					<svg-icon @click="delResource(resourceIndex,index)" iconClass="delBucket"></svg-icon>
				</div>
		  </div>
		</div>
		<van-button class="Next-Step" color="#e54e17" type="primary" block>下一步</van-button>
	</div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { mapState,mapMutations } from 'vuex'
import ResourceSelect from '@/components/Register/ResourceSelect'
export default {
	name:  'Bussiness',
	components: {
		ResourceSelect
	},
	// 定义属性
	data() {
		return {
			
		}
	},
	// 计算属性，会监听依赖属性值随之变化
	computed: {
		...mapState('supplier',['supplierInfoData'])
	},
	// 监控data中的数据变化
	watch: {},
	// 方法集合
	methods: {
		...mapMutations('supplier',['AGENTTYPE_SET','DEL_RESOURCEAGENTS']),
		resourceSelect(item,index) {
			if (item.type === 0) {
			  this.$router.push({name: 'ResourceSelect',query: {state: item.state,index: index}});
			}
			if (item.type === 1) {
				this.$router.push({name: 'AreaSelect',query: {state: item.state,index: index}});
			}
		},
		checkClike(type,resourceAgents,resource,index) {
      resource.agentType = type
			let _obj = {
				checkReourceList: resourceAgents,
				index: index
			}
      this.AGENTTYPE_SET(_obj)
		},
		delResource(resourceIndex,index) {
			this.DEL_RESOURCEAGENTS({resourceIndex: resourceIndex,index: index})
		}
	},
	// 生命周期 - 创建完成（可以访问当前this实例）
	created() {
		
	},
	// 生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
	},
	beforeCreate() {}, // 生命周期 - 创建之前
	beforeMount() {}, // 生命周期 - 挂载之前
	beforeUpdate() {}, // 生命周期 - 更新之前
	updated() {}, // 生命周期 - 更新之后
	beforeDestroy() {}, // 生命周期 - 销毁之前
	destroyed() {}, // 生命周期 - 销毁完成
	activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
	.check-top-swich {
		transform: scale(0.8);
		margin-top: 1px;
		.swich-isActive {
			background: #e54e17;
			color: white;
			//opacity: 0.7;
		}
		>span {
			font-size: 12px;
			border: 1px solid #EAEAEA;
			padding-top: 5px;
			padding-bottom: 5px;
			background: white;
			color: rgba(149, 144, 144, 1);
		}
		span:nth-child(1) {
			border-radius: 10px 0 0 10px;
			padding-left: 10px;
			padding-right: 10px;
			margin:0px -1px -1px 0px ;
		}
		span:nth-child(2) {
			border-radius: 0 10px 10px 0;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.Top-Tips {
		color: rgba(0, 123, 203, 1);
		font-size: 0.32rem;
		font-weight: 700;
		font-family: PingFangSC-semiBold;
		height: 0.7rem;
		display: flex;
    align-items: center;
		margin-bottom: 10px;
	}
	.Initial-Business-Top {
		display: flex;
    justify-content: space-between;
	}
	.Initial-Content-Resource {
		display: flex;
		margin-bottom: 10px;
		justify-content: space-between;
		padding-top: 10px;
    padding-bottom: 10px;
		border-radius: 0.12rem;
		background-color: rgba(255, 255, 255, 1);
		font-family: -apple-system;
		border: 1px solid #EEEEEE;
		.ICR-Name {
			color: rgba(117, 117, 117, 1);
			font-size: 0.28rem;
			font-family: PingFangSC-regular;
			display: flex;
      align-items: center;
			padding-left: 10px;
			width: calc(100% - 157px);
			//max-width: 2.8rem;
		}
		.ICR-Right {
			display: flex;
			position: relative;
			padding-right: 15px;
			right: -0.14rem;
			width: 155px;
			.ICR-Check {
				display: inline-block;
				background-color: #F3F4F4;
				border-radius: 10px;
				color: #959090;
				font-size: 12px;
				>span:nth-child(1) {
         padding-left: 5px;
				 padding-right: 5px;
				 padding-top: 3px;
				 padding-bottom: 3px;
				 text-align: center;
				}
				>span:nth-child(2) {
         padding-left: 5px;
				 padding-right: 5px;
				 padding-top: 3px;
				 padding-bottom: 3px;
				 text-align: center;
				}
			}
			.ICR-Check-True {
				background: #e54e17;
				color: white;
			}
		}
	}
	.Initial-right {
		width: 2rem;
		display: flex;
		justify-content: space-around;
		line-height: 0.32rem;
		height: 0.7rem;
		border-radius: 0.12rem 0.2rem 0.2rem 0.12rem;
		background-color: rgba(255, 255, 255, 1);
		color: rgba(82, 82, 82, 0.67);
		font-size: 0.24rem;
		text-align: center;
		font-family: -apple-system;
		border: 1px solid #eeeeee;
		>span {
      display: flex;
			align-items: center;
		}
	}
</style>