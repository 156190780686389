var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Bussiness" },
    [
      _vm._l(
        _vm.supplierInfoData
          .filter((x) => x.checkLabel)
          .map((c) => c.businessList)
          .flat(),
        function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "Initial-Business" },
            [
              _c("div", { staticClass: "Initial-Business-Top" }, [
                _c("span", { staticClass: "Initial-Name Top-Tips" }, [
                  _vm._v(" " + _vm._s(item.name) + " "),
                ]),
                _c(
                  "div",
                  { staticClass: "Initial-right" },
                  [
                    _c("span", [_vm._v("维护")]),
                    _c("svg-icon", {
                      staticStyle: { "margin-top": "1px" },
                      attrs: { iconClass: "add1" },
                      on: {
                        click: function ($event) {
                          return _vm.resourceSelect(item, index)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._l(item.resourceAgents, function (resource, resourceIndex) {
                return _c(
                  "div",
                  {
                    key: resourceIndex,
                    staticClass: "Initial-Content-Resource",
                  },
                  [
                    _c("span", { staticClass: "ICR-Name" }, [
                      _vm._v(_vm._s(resource.nameCn)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "ICR-Right" },
                      [
                        _c("div", { staticClass: "check-top-swich" }, [
                          _c(
                            "span",
                            {
                              class:
                                resource.agentType === 1
                                  ? "swich-isActive"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.checkClike(
                                    1,
                                    item.resourceAgents,
                                    resource,
                                    index
                                  )
                                },
                              },
                            },
                            [_vm._v("资源方")]
                          ),
                          _c(
                            "span",
                            {
                              class:
                                resource.agentType === 0
                                  ? "swich-isActive"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.checkClike(
                                    0,
                                    item.resourceAgents,
                                    resource,
                                    index
                                  )
                                },
                              },
                            },
                            [_vm._v("代理")]
                          ),
                        ]),
                        _c("svg-icon", {
                          attrs: { iconClass: "delBucket" },
                          on: {
                            click: function ($event) {
                              return _vm.delResource(resourceIndex, index)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          )
        }
      ),
      _c(
        "van-button",
        {
          staticClass: "Next-Step",
          attrs: { color: "#e54e17", type: "primary", block: "" },
        },
        [_vm._v("下一步")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }