var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SupplierType" },
    [
      _c("p", { staticClass: "Top-Tips" }, [
        _vm._v("请选择您可提供的产品类型及服务区域，以便您收到相应的询价！"),
      ]),
      _c("p", { staticClass: "Company-Type" }, [_vm._v("我能提供的产品类型")]),
      _c(
        "div",
        { staticClass: "Type-Check" },
        _vm._l(_vm.supplierInfoData, function (item, index) {
          return _c(
            "span",
            {
              key: index,
              class: item.checkLabel
                ? "Name-Span Name-Span-Check"
                : "Name-Span",
              on: {
                click: function ($event) {
                  return _vm.checkName(index)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.name) + " ")]
          )
        }),
        0
      ),
      _c("Bussiness"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }